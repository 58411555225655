.App {
  text-align: center;
  font-family: sans-serif; 
}

.App-header {
  background-color: #15161b;
  background-image: radial-gradient(#2b2e41, #15161b);
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

span.emoji {
  font-size: 100px;
  vertical-align: middle;
  line-height: 2;
}